<template>
  <div>
    <Plan ref="planModal" @updatePlan="updatePlan" />
    <div class="card">
      <div class="section-title mt-4">
        <div
          class="wrapper content-center px-3 pt-4 mb-4"
          style="justify-content: space-between"
        >
          <h5 class="card-heading">{{ title }}</h5>
        </div>
        <div class="row table-tabs">
          <div class="tab-title">
            <div
              class="tabs mt-5"
              title="Campaign"
              @click="campaignTab"
              :class="{ active: isActive === 'Campaign' }"
            >
              Campaign
            </div>
            <div
              class="tabs mt-5"
              title="Ad Group"
              v-if="this.adType"
              @click="adGroup"
              :class="{ active: isActive === 'Ad Group' }"
            >
              Ad Group
            </div>
            <div
              class="tabs mt-5"
              title="Ad Type"
              v-if="this.keywordData"
              @click="adTypeTab"
              :class="{ active: isActive === 'Ad Type' }"
            >
              <span v-if="selected.Channel === 'Google_Ads'">Keyword</span>
              <span v-else>Ad Type</span>
            </div>
          </div>
          <CampaignTable
            class="mt-3"
            v-if="this.campaignTable"
            :items="campaignTableData"
            :tableLoading="campaigntableLoading"
            :fields="fields"
            :recordId="this.selectedRecordId"
            @adTypeStatus="adTypeStatus($event)"
            :currentPage="campaignCurrentPage"
            :perPage="campaignPerPage"
            :totalRows="campaignTotoalRows"
            @getSelectedPageEvent="$emit('getCampaignPageEvent', $event)"
          />
          <AdGroupTable
            v-if="this.adGroupTable"
            :items="adGroupTableData"
            :recordId="this.selectedAdGroupRecordId"
            :tableLoading="adGroupTableLoading"
            @keywordDataStatus="keywordDataStatus"
            :fields="adGroupFields"
            :currentPage="adGroupCurrentPage"
            :perPage="adGroupPerPage"
            :totalRows="adGroupTotoalRows"
            @getSelectedPageEvent="$emit('getAdGroupPageEvent', $event)"
          />
          <AdTypeTable
            v-if="this.adTypedataTable"
            @finalStatus="finalStatus"
            :tableLoading="adTypeTableLoading"
            :recordId="this.selectedRecordId"
            :items="adTypeTableData"

            :fields="adTypeFields"
            :currentPage="adTypeCurrentPage"
            :perPage="adTypePerPage"
            :totalRows="adTypeTotoalRows"
            @getSelectedPageEvent="$emit('getAdTypePageEvent', $event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Plan from "@/components/Modal/Plan.vue";
import CampaignTable from "@/components/Table/CampaignTable.vue";
import AdGroupTable from "@/components/Table/AdGroupTable.vue";
import AdTypeTable from "@/components/Table/AdTypeTable.vue";
export default {
  name: "HealthMonitorTable",
  props: {
    filterBy: {
      type: Object,
      default: () => {},
    },
    campaigntableLoading:{
      type: Boolean,
      default: false
    },
    adGroupTableLoading:{
      type: Boolean,
      default: false
    },
    adTypeTableLoading:{
      type: Boolean,
      default: false
    },
    campaignCurrentPage: {
      type: Number,
      default: 1,
    },
    campaignPerPage: {
      type: Number,
      default: 5,
    },
    campaignTotoalRows: {
      type: Number,
      default: 0,
    },

    adGroupPerPage: {
      type: Number,
      default: 5,
    },
    adGroupTotoalRows: {
      type: Number,
      default: 0,
    },
    adGroupCurrentPage: {
      type: Number,
      default: 1,
    },

    adTypePerPage: {
      type: Number,
      default: 5,
    },
    adTypeTotoalRows: {
      type: Number,
      default: 0,
    },
    adTypeCurrentPage: {
      type: Number,
      default: 1,
    },
    // extraHeader: {
    //   type: Array,
    //   default: () => [],
    // },
    title: {
      type: String,
      default: "Title",
    },
    fields: {
      type: Array,
      default: () => [],
    },
    adGroupFields: {
      type: Array,
      default: () => [],
    },
    adTypeFields: {
      type: Array,
      default: () => [],
    },
    campaignTableData: {
      type: Array,
      default: () => [],
    },
    adGroupTableData: {
      type: Array,
      default: () => [],
    },
    adTypeTableData: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Plan,
    CampaignTable,
    AdGroupTable,
    AdTypeTable,
  },
  watch: {
    // extraHeader(newVal) {
    //   this.fields = [
    //     {
    //       key: "campaign_name",
    //       sortable: true,
    //       tdClass: "text-center",
    //       thClass: "text-center",
    //     },
    //     {
    //       key: "ad_type",
    //       sortable: false,
    //       tdClass: "text-center",
    //       thClass: "text-center",
    //     },
    //   ];
    //   newVal.forEach((element) => {
    //     const obj = {
    //       key: element
    //         .replace(/\s(.)/g, function(a) {
    //           return a.toUpperCase();
    //         })
    //         .replace(/\s/g, "")
    //         .replace(/^(.)/, function(b) {
    //           return b.toLowerCase();
    //         }),
    //       sortable: true,
    //       tdClass: "text-center",
    //       thClass: "text-center",
    //     };
    //     this.fields.push(obj);
    //   });
    // },
    filterBy(newVal) {
      this.filterOn = [];
      this.filterOn.push(newVal.id);
    },
  },
  data() {
    return {
      tabTitles: false,
      campaign: true,
      adType: false,
      keywordData: false,
      campaignTable: true,
      adGroupTable: false,
      adTypedataTable: false,
      marketTypedropdown: [{ text: "Show/Hide", id: "1" }],
      // fields: [],
      
      selected: [],
      rows: null,
      perPage: 10,
      currentPage: 1,
      overDelete: false,
      overView: false,
      overShare: false,
      filter: null,
      filterOn: [],
      filteredItems: [],
      campaignHeader: [],
      selectedRecordId: 0,
      selectedAdGroupRecordId: 0,
      isActive: "Campaign",
    };
  },
  mounted() {
    this.filteredItems = this.items;
  },
  methods: {
    onRowSelected(items) {
      this.isActive = "Campaign";
      this.selectedRecordId = parseInt(items.id);
      this.tabTitles = this.campaign = this.campaignTable = true;
    },
    campaignTab() {
      this.campaignTable = true;
      this.adGroupTable = this.adTypedataTable = false;
      this.isActive = "Campaign";
    },
    adGroup() {
      this.campaignTable = this.adTypedataTable = false;
      this.adGroupTable = true;
      this.isActive = "Ad Group";
    },
    adTypeTab() {
      this.campaignTable = this.adGroupTable = false;
      this.adTypedataTable = true;
      this.isActive = "Ad Type";
    },
    adTypeStatus(e) {
      this.isActive = "Ad Group";
      this.selectedRecordId = e;
      this.campaignTable = this.adTypedataTable = this.keywordData = false;
      this.adType = this.adGroupTable = true;
      this.$emit("getAdGroupTableData", e);
    },
    keywordDataStatus(e) {
      this.isActive = "Ad Type";
      this.selected = e;
      this.selectedAdGroupRecordId = 0;
      this.adGroupTable = false;
      this.adTypedataTable = this.keywordData = true;
      this.$emit("getAdTypeTableData", e);
    },
    finalStatus(items) {
      console.log("items", items);
    },
    updateSelectRow(index, val) {
      if (val) {
        this.$refs.selectableTable.selectRow(index);
      } else {
        this.$refs.selectableTable.unselectRow(index);
      }
    },
    updatePlan(val) {
      if (val === "delete") {
        this.$emit("updateToast", {
          show: true,
          type: "error",
          message: "Our system has deleted your plan successfully!",
        });
      } else if (val === "share") {
        this.$emit("updateToast", {
          show: true,
          type: "success",
          message: "Yay, your plan has been shared successfully!",
        });
      }
    },
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.currentPage = 1;
      this.filteredItems = filteredItems;
    },
  },
};
</script>

<style scoped>
.card-heading {
  font-family: ProximaNovaBold;
  font-size: 20px;
  color: #222a37;
}
.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.column-heading {
  font-family: ProximaNovaBold;
  font-size: 16px;
  color: #222a37;
  margin: 40px 0;
}
.column-data {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #222a37;
  font-weight: normal;
}
.finance-heading {
  color: #9aafd4;
}
.badge {
  padding: 8px;
  background-color: #deffe6;
  border: 1px solid #81f89e;
}
>>> .table > tbody > tr {
  outline: none;
}
>>> .table > tbody > tr > td {
  padding: 24px;
}
>>> .table > tbody > .table-active > td {
  background-color: #eff5ff;
}
>>> .table > thead {
  box-shadow: 0 4px 7px #c9ced5;
}
>>> .table > thead > tr > th {
  outline: none;
  padding: 24px 24px;
}
>>> .table > :not(:last-child) > :last-child > * {
  border: none;
}
>>> .table.b-table > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table > tfoot > tr > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right 1.5rem center;
}
.form-check-input {
  height: 24px;
  position: relative;
  top: 0px;
  margin: 0px;
  margin-right: 10px;
}

.form-check-input:checked {
  background-color: #222a37;
  border-color: #222a37;
}
.card {
  box-shadow: 0 0 8px 0 #c9ced5;
}
.image-wrapper-active {
  position: relative;
  background-color: #050505;
}
.tooltip-wrapper {
  width: 80px;
  height: 56px;
  background-color: #050505;
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  font-family: ProximaNovaRegular;
  clip-path: polygon(100% 0, 100% 53%, 50% 86%, 0 53%, 0 0);
  padding-top: 4px;
}
.search-wrapper {
  width: 318px;
  height: 60px;
  background-color: #eff5ff;
  display: flex;
  align-items: center;
  padding: 0;
}
.search-wrapper-collapse {
  width: 60px;
  background-color: transparent;
}
input[type="text"] {
  border: none;
  border-color: #c6ccd6;
  margin-left: 0;
  background-color: #eff5ff;
  box-shadow: none;
  height: 60px;
  font-size: 16px;
}

input::placeholder {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #9aafd4;
}
input:focus {
  background-color: #eff5ff;
  outline: none;
  box-shadow: none;
}
.image {
  padding: 0 16px;
}

>>> .page-item.disabled .page-link {
  background-color: transparent;
}
>>> .page-link {
  height: 36px;
  width: 36px;
  background-color: transparent;
  border: none;
  font-size: 16px;
  font-family: ProximaNovaRegular;
  color: #9aafd4;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
}

>>> .page-link i {
  font-style: normal;
}

>>> .active > .page-link {
  background-color: transparent;
  border: 2px solid #050505;
  font-size: 16px;
  font-family: ProximaNovaRegular;
  color: #222a37;
  box-shadow: none;
}
.no-items-wrapper {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-items-description {
  opacity: 0.5;
  font-family: ProximaNovaBold;
  font-size: 16px;
  color: #222a37;
  margin-top: 42px;
}
.card-header.font-large.ProximaNovaBold {
  font-size: 20px;
}
>>> td {
  font-size: 16px !important;
  font-family: ProximaNovaRegular;
  color: #222a37;
}
>>> .nav-tabs {
  border-bottom: 0;
}
>>> .nav-tabs .nav-link {
  border: 0;
}
>>> .nav-tabs > li > a {
  border: 0;
  border-radius: 3px 3px 0 0;
  font-size: 18px;
  font-family: ProximaNovaRegular;
}
.row.table-tabs {
  margin: 0 28px;
}
.tabs {
  width: 10%;
  font-size: 18px;
  margin-bottom: 15px;
  font-family: ProximaNovaRegular;
  float: left;
  cursor: pointer;
}
.tabs.active {
  text-decoration: underline;
  font-family: ProximaNovaBold;
}
</style>
