<template>
  <div>
    <div class="card">
      <div class="overflow-auto">
        <b-table
          id="spaceTable"
          :items="items"
          :fields="fields"
          select-mode="single"
          selectable
          @row-clicked="onRowClicked"
          @row-selected="onRowSelected"
          class="mb-0"
          :total-rows="totalRows"
          hover
          :busy="tableLoading"
          responsive="sm"
          ref="selectableTable"
        >
          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
          <template #head()="data">
            <span class="column-heading">{{ data.label }}</span>
          </template>
          <template #head(selected)="">
            <span></span>
          </template>
          <template #head(marketKpi)="">
            <span class="column-heading">Market & KPI's</span>
          </template>

          <template #cell(planName)="data">
            <span class="column-data"> {{ data.item.planName }}</span>
          </template>
          <template #cell(marketKpi)="data">
            <div>
              <b-badge
                v-for="(item, index) in data.item.marketKpi"
                :key="index"
                class="mb-2 mr-2 column-data"
                >{{ item }}</b-badge
              >
            </div>
          </template>
          <template #cell(model)="data">
            <span class="column-data"> {{ data.item.model }}</span>
          </template>
          <template #cell(revenue)="data">
            <div class="mb-2">
              <span class="column-data finance-heading"> Previous: </span>
              <span class="column-data">
                ${{ data.item.revenue.previous }}Mn
              </span>
            </div>
            <div class="mb-2">
              <span class="column-data finance-heading">Predicted: </span>
              <span class="column-data">
                ${{ data.item.revenue.predicted }}Mn
              </span>
            </div>
          </template>
          <template #cell(budget)="data">
            <div class="mb-2">
              <span class="column-data finance-heading"> Previous: </span>
              <span class="column-data">
                ${{ data.item.budget.previous }}Mn
              </span>
            </div>
            <div class="mb-2">
              <span class="column-data finance-heading">Predicted: </span>
              <span class="column-data">
                ${{ data.item.budget.predicted }}Mn
              </span>
            </div>
          </template>
          <template #cell(createdOn)="data">
            <span class="column-data"> {{ data.item.createdOn }}</span>
          </template>
        </b-table>
      </div>
    </div>
    <w-pagination
      :currentPage="currentPage"
      :perPage="perPage"
      :totalRows="totalRows"
      @getSelectedPageEvent="$emit('getSelectedPageEvent', $event)"
    />
    <div v-if="!items.length && !tableLoading" class="no-items-wrapper">
      <div class="text-center">
        <img src="@/assets/tech-life-media2.png" alt="" />
        <div v-if="!items.length" class="no-items-description">
          No Ad Type have been added yet
        </div>
        <!--<div v-else-if="!filteredItems.length" class="no-items-description">
          Oops!<br />
          Sorry to say, it seems like no plan has been found
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/widgets/Pagination.vue";
export default {
  name: "CampaignTable",
  components: {
    "w-pagination": Pagination,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    recordId: {
      default: 0,
    },
    fields: {
      type: Array,
      default: () => [],
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 5,
    },
    totalRows: {
      type: Number,
      default: 0,
    },
    tableLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      overDelete: false,
      overView: false,
      overShare: false,
      selected: [],
    };
  },
  mounted() {},
  methods: {
    onRowSelected(items) {
      items.selected = true;
      this.selected = items;
    },
    onRowClicked(items) {
      items.selected = true;
      this.selected = items;
      this.$emit("finalStatus", this.selected);
    },
  },
};
</script>
<style scoped>
.column-heading {
  font-family: ProximaNovaBold;
  font-size: 16px;
  color: #222a37;
  margin: 40px 0;
}
.column-data {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #222a37;
  font-weight: normal;
}
.finance-heading {
  color: #9aafd4;
}
.badge {
  padding: 8px;
  background-color: #deffe6;
  border: 1px solid #81f89e;
}
>>> .table > tbody > tr {
  outline: none;
}
>>> .table > tbody > tr > td {
  padding: 24px;
}
>>> .table > tbody > .table-active > td {
  background-color: #eff5ff;
}
>>> .table > thead {
  box-shadow: 0 4px 7px #c9ced5;
}
>>> .table > thead > tr > th {
  outline: none;
  padding: 24px 24px;
}
>>> .table > :not(:last-child) > :last-child > * {
  border: none;
}
>>> .table.b-table > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table > tfoot > tr > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right 1.5rem center;
}
.form-check-input {
  height: 24px;
  position: relative;
  top: 0px;
  margin: 0px;
  margin-right: 10px;
}

.form-check-input:checked {
  background-color: #222a37;
  border-color: #222a37;
}
.card {
  box-shadow: 0 0 8px 0 #c9ced5;
}
.image-wrapper-active {
  position: relative;
  background-color: #050505;
}
.tooltip-wrapper {
  width: 80px;
  height: 56px;
  background-color: #050505;
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  font-family: ProximaNovaRegular;
  clip-path: polygon(100% 0, 100% 53%, 50% 86%, 0 53%, 0 0);
  padding-top: 4px;
}
.search-wrapper {
  width: 318px;
  height: 60px;
  background-color: #eff5ff;
  display: flex;
  align-items: center;
  padding: 0;
}
.search-wrapper-collapse {
  width: 60px;
  background-color: transparent;
}
input[type="text"] {
  border: none;
  border-color: #c6ccd6;
  margin-left: 0;
  background-color: #eff5ff;
  box-shadow: none;
  height: 60px;
  font-size: 16px;
}

input::placeholder {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #9aafd4;
}
input:focus {
  background-color: #eff5ff;
  outline: none;
  box-shadow: none;
}
.image {
  padding: 0 16px;
}

>>> .page-item.disabled .page-link {
  background-color: transparent;
}
>>> .page-link {
  height: 36px;
  width: 36px;
  background-color: transparent;
  border: none;
  font-size: 16px;
  font-family: ProximaNovaRegular;
  color: #9aafd4;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
}

>>> .page-link i {
  font-style: normal;
}

>>> .active > .page-link {
  background-color: transparent;
  border: 2px solid #050505;
  font-size: 16px;
  font-family: ProximaNovaRegular;
  color: #222a37;
  box-shadow: none;
}
.no-items-wrapper {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-items-description {
  opacity: 0.5;
  font-family: ProximaNovaBold;
  font-size: 16px;
  color: #222a37;
  margin-top: 42px;
}
.card-header.font-large.ProximaNovaBold {
  font-size: 20px;
}
>>> td {
  font-size: 16px !important;
  font-family: ProximaNovaRegular;
  color: #222a37;
}
>>> .nav-tabs {
  border-bottom: 0;
}
>>> .nav-tabs .nav-link {
  border: 0;
}
>>> .nav-tabs > li > a {
  border: 0;
  border-radius: 3px 3px 0 0;
  font-size: 18px;
  font-family: ProximaNovaRegular;
}
.row.table-tabs {
  margin: 0 15px;
}
</style>
